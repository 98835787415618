








































































import { Component, Vue } from 'vue-property-decorator';

import { SITE_PARSER_UPDATE_RATE } from '../../../constants';
import ProgressBar from '@/components/ProgressBar.vue';
import TooltipBase from '@/components/TooltipBase.vue';
import { readSiteParserTemplates } from '@/store/site-parser-template/getters';
import {
  dispatchDeleteSiteParserTemplate,
  dispatchGetSiteParserTemplates,
} from '@/store/site-parser-template/actions';

@Component({
  components: {
    ProgressBar,
    TooltipBase,
  },
})
export default class SiteParserTemplate extends Vue {
  public dialog = false;
  public deleteDomain: string|null = null;
  public headers = [
    {
      text: 'Сайт',
      sortable: true,
      value: 'domain',
      align: 'left',
    },
    {
      text: 'baseUrl',
      sortable: true,
      value: 'baseUrl',
      align: 'left',
    },
    {
      text: 'Действия',
      value: 'domain',
    },
  ];

  public updateTimer?: number = undefined;

  get tasks() {
    return readSiteParserTemplates(this.$store);
  }

  public tableLoaded = false;

  public async mounted() {
    await dispatchGetSiteParserTemplates(this.$store);
    this.tableLoaded = true;
    this.updateTimer = setInterval(async () => {
      await dispatchGetSiteParserTemplates(this.$store);
      }, SITE_PARSER_UPDATE_RATE,
    );
  }

  public destroyed() {
    clearInterval(this.updateTimer);
    this.updateTimer = undefined;
  }

  public async dialogDeleteTask() {
    if (this.deleteDomain) {
      await dispatchDeleteSiteParserTemplate(
        this.$store,
        { domain: this.deleteDomain });
      await dispatchGetSiteParserTemplates(this.$store);
    }
    this.deleteDomain = null;
    this.dialog = false;
  }

  public async dialogCancel() {
    this.deleteDomain = null;
    this.dialog = false;
  }

  public async deleteTaskDialog(domain: string) {
    this.dialog = true;
    this.deleteDomain = domain;
  }

}
